import * as React from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Container,
  styled,
  Paper,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import pages from "../schemas/pages";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  lineHeight: 2,
  boxShadow: "none",
}));

export default function Footer() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "#081A28",
          opacity: 0.8,
        }}
      >
        <CssBaseline />
        <Container component="main">
          <Box sx={{ flexGrow: 1, m: 5 }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={5} lg={4}>
                <Box component="div">
                  <Box
                    component="img"
                    src="/photos/logo/logoCardBorderBlack.jpg"
                    sx={{
                      width: { xs: "97%", md: "80%" },
                      height: 125,
                      objectFit: "cover",
                    }}
                    alt="Logo GGSinergi"
                  />
                  <Typography
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      margin: "10px",
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    PT GREEN GEO SINERGI established in 2021
                  </Typography>
                </Box>
              </Grid>
              <Grid
                justifyContent={"right"}
                container
                xs={12}
                md={7}
                lg={8}
                spacing={4}
              >
                <Grid xs={12} lg={4}>
                  <Item>
                    <Box
                      id="category-c"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        pb: 2,
                      }}
                    >
                      Our Services
                    </Box>
                    <Box
                      component="div"
                      aria-labelledby="category-c"
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {pages.map((page, i) => (
                        <li key={i}>
                          <Link
                            className="link-href"
                            style={{ color: "white" }}
                            to={`/${page.toLowerCase().split(" ").join("-")}`}
                          >
                            {page}
                          </Link>
                        </li>
                      ))}
                    </Box>
                  </Item>
                </Grid>
                <Grid xs={12} lg={5}>
                  <Item>
                    <Box
                      id="category-d"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        pb: 2,
                      }}
                    >
                      Location
                    </Box>
                    <Box component="div" aria-labelledby="category-d">
                      <Typography
                        sx={{ textAlign: "left", fontSize: "14px", mb: "10px" }}
                      >
                        Office : Perum Griya Permata Asri Jl. Kapuas 285,
                        Balikpapan 76114
                      </Typography>

                      <Typography sx={{ textAlign: "left", fontSize: "14px" }}>
                        Work station : Jalan Mayjend Sutoyo nomor 39, RT 044,
                        Kelurahan Klandasan Ilir, Kecamatan Balikpapan Kota,
                        76113
                      </Typography>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: "column", sm: "row" }}
                sx={{ fontSize: "12px" }}
              >
                <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                  <Item>© Copyright</Item>
                </Grid>
                <Grid
                  container
                  columnSpacing={1}
                  sx={{ order: { xs: 1, sm: 2 } }}
                >
                  <Grid>
                    <Item>Instagram</Item>
                  </Grid>
                  <Grid>
                    <Item>Facebook</Item>
                  </Grid>
                  {/* <Grid>
                    <Item>Link C</Item>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: "#081A28",
        }}
      >
        <Container maxWidth="sm">
          <Typography align="center" variant="body1" sx={{ color: "white" }}>
            GREEN GEO SINERGI. All Rights Reserved
          </Typography>
        </Container>
      </Box>
    </>
  );
}
