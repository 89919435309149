import { useState } from "react";
import {
  AppBar,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Box,
  styled,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink } from "react-router-dom";

const pages = ["About", "Contact", "Project List"];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const drawerWidth = 241;

function MenuNavbar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="sticky" color="transparent" style={styles.navbar}>
        <Container>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
                sx={{ color: "white" }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                noWrap
                component="div"
                sx={{
                  flexGrow: 1,
                  mr: 2,
                  my: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "right",
                  alignContent: "center",
                }}
              >
                <Link to="/">
                  <img
                    src="/photos/logo/logoBackgroundTrans.png"
                    style={{ width: 160, height: 60, objectFit: "cover" }}
                    alt="Logo GGSinergi"
                    loading="lazy"
                  />
                </Link>
              </Typography>
            </Box>
            <Typography
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                mr: 2,
                my: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Link to="/">
                <img
                  src="/photos/logo/logoBackgroundTrans.png"
                  style={{ width: 160, height: 60 }}
                  alt="Logo GGSinergi"
                  loading="lazy"
                />
              </Link>
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
                alignContent: "center",
              }}
            >
              {pages.map((page, i) => (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-active" : "text-hover"
                  }
                  key={i}
                  to={`/${page.toLowerCase().split(" ").join("-")}`}
                  style={styles.menuText}
                >
                  {page}
                </NavLink>
              ))}
              <a
                className="text-hover"
                href="https://admin.ggsinergi.com/login"
                style={styles.menuText}
                target="_blank" rel="noopener noreferrer"
              >
                Login
              </a>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        onClose={() => {
          handleDrawerClose();
        }}
        open={open}
      >
        <DrawerHeader sx={{ justifyContent: "space-between" }}>
          <Link to="/">
            <img
              src="/photos/logo/logoBackgroundTrans.png"
              style={{ width: 160, height: 50, objectFit: "cover" }}
              alt="Logo GGSinergi"
              loading="lazy"
            />
          </Link>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((link, index) => {
            return (
              <ListItem
                button
                component={Link}
                to={`/${link.toLowerCase().split(" ").join("-")}`}
                key={index}
              >
                <ListItemText
                  sx={{
                    color: "#081A28",
                    textAlign: "center",
                  }}
                  primary={link}
                />
                <Divider />
              </ListItem>
            );
          })}
        </List>
        <a
          style={{
            color: "#081A28",
            textAlign: "center",
            textDecoration: 'none'
          }}
          href="https://admin.ggsinergi.com/login"
          target="_blank" rel="noopener noreferrer"
        >
          Login
        </a>
      </Drawer>
    </>
  );
}

const styles = {
  navbar: {
    backgroundColor: "#081A28",
    paddingBottom: "3px",
  },
  menuText: {
    padding: "6px 10px",
    color: "white",
    margin: "6px",
    fontSize: "20px",
    fontWeight: "bold",
    textDecoration: "none",
    fontFamily: "roboto-bold",
  },
};

export default MenuNavbar;
