import "./App.css";
import { Route, Routes } from "react-router-dom";
import * as Page from "./pages";
import WorkShopPage from "./pages/servicePage/WorkshopPage";
import ScrollToTop from "./components/ScrollToTop";
import { Suspense } from "react";
import Loading from "./components/Loading";
import RequireAuth from "./components/RequireAuth";
import Home from "./pages/home";
import Layout from "./layout/Layout";
import Sidebar from "./pages/dashboardPage/Sidebar";

export const LoadingLazy = () => <Loading />;

function App() {
  return (
    <div className="App">
      <Suspense fallback={<LoadingLazy />}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          >
            <Route>
              <Route index path="" element={<Page.Dashboard />} />
              <Route index path="/sidebar" element={<Sidebar />} />
            </Route>
          </Route>
          <Route path="/" element={<Layout />}>
            <Route exact path="/home" element={<Page.HomePage />} />
            <Route exact path="/about" element={<Page.AboutPage />} />
            <Route
              exact
              path="/coal-and-mineral-drilling-services-detail"
              element={<Page.ServicePage />}
            />
            <Route
              exact
              path="/geotechnical-drilling-and-soil-investigation-detail"
              element={<Page.ServicePage />}
            />
            <Route
              exact
              path="/surface-mapping-detail"
              element={<Page.ServicePage />}
            />
            <Route exact path="/workshop-detail" element={<WorkShopPage />} />
            <Route
              exact
              path="/project-list"
              element={<Page.ProjectListPage />}
            />
            <Route exact path="/contact" element={<Page.ContactPage />} />
            {/* <Route exact path="/login" element={<Page.LoginPage />} /> */}
            <Route exact path="*" element={<Page.Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
