import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  width: "10wh",
}));

const data = [
  {
    src: "/photos/workshop/ggs120j.jpeg",
    title: "ggsinergi-workshop-ggs120j",
  },
  {
    src: "/photos/workshop/ggs175j.jpeg",
    title: "ggsinergi-workshop-ggs175j",
  },
  {
    src: "/photos/workshop/ggs250j.jpeg",
    title: "ggsinergi-workshop-ggs250j",
  },
];

export default function WorkshopPage() {
  return (
    <>
      <Container sx={{ my: 10 }}>
        <Box sx={{ flexGrow: 1, backgroundColor: "#f3f3f3" }}>
          <Grid container spacing={1} sx={{ backgroundColor: "#f3f3f3" }}>
            <Grid item xs={12} md={8}>
              <Item sx={{ boxShadow: 0 }}>
                <Box
                  component="img"
                  loading="lazy"
                  src={"/photos/services/workshop.jpeg"}
                  alt="call to action"
                  sx={{
                    width: "80%",
                    maxWidth: "100%",
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item sx={{ height: "100%", p: 5 }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: { xs: "24px", md: "28px" },
                    fontWeight: "bold",
                    mb: 3,
                  }}
                >
                  Workshop
                </Typography>
                <Typography
                  paragraph={true}
                  align="justify"
                  sx={{ fontSize: { xs: "14px", md: "16px" } }}
                >
                  In order to deliver our best work, we have the workshop to
                  built, assemble and maintain our equipments.
                </Typography>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Grid
        container
        wrap="nowrap"
        sx={{
          display: { xs: "flex", md: "row" },
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: "#f3f3f3",
          justifyContent: "center",
          my: 10,
        }}
      >
        {data.map((item, index) => (
          <Box
            key={index}
            sx={{
              width: { sx: 100, md: 600 },
              maxWidth: 400,
              p: { xs: 4, md: 10 },
            }}
          >
            {item ? (
              <img
                loading="lazy"
                style={{ width: 330, height: 420 }}
                alt={item.title}
                src={item.src}
              />
            ) : null}

            {item ? (
              <Box>
                <Typography sx={{ mb: 2 }} gutterBottom variant="h5">
                  {item.title.split("-").join(" ")}
                </Typography>

                <Stack spacing={2}>
                  <Link to={`${item.src}`} target="_blank" download>
                    <Button
                      variant="outlined"
                      color="primary"
                      // sx={{ color: "#081A28", borderColor: "#081A28" }}
                      endIcon={<DownloadIcon />}
                    >
                      Download
                    </Button>
                  </Link>
                </Stack>
              </Box>
            ) : null}
          </Box>
        ))}
      </Grid>
    </>
  );
}
