import React from "react";

const HomePage = React.lazy(() => import("./homePage/HomePage"));
const AboutPage = React.lazy(() => import("./aboutPage/AboutPage"));
const ContactPage = React.lazy(() => import("./contactPage/ContactPage"));
// const LoginPage = React.lazy(() => import("./loginPage/LoginPage"));
// const LoginPage = React.lazy(() => import("./loginPage/Login"));
const ProjectListPage = React.lazy(() =>
  import("./projectListPage/ProjectListPage")
);
const Page404 = React.lazy(() => import("./page404/Page404"));
const ServicePage = React.lazy(() => import("./servicePage/ServicePage"));
const Dashboard = React.lazy(() => import("./dashboardPage/Dashboard"));

export {
  HomePage,
  AboutPage,
  ContactPage,
  // LoginPage,
  ProjectListPage,
  Page404,
  ServicePage,
  Dashboard,
};
