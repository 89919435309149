import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={50} thickness={5} color="secondColor" />
      <Typography variant={"subtitle1"} color="blueDark" sx={{ mt: 3 }}>
        Loading...
      </Typography>
    </Box>
  );
};

export default Loading;
